import api from './api/api';
import { envService } from '../helpers/env.helper';
import { Article } from '../models/article';
import { ProductActiveStatus } from '../models/product-active-status';
import { Rating } from '../models/rating';
import { RatingReason } from '../models/rating-reason';
import { ArticleSearchResult, QuickResponseModel } from '../models/search-entity';
import { Section } from '../models/section';

const getArticle = async (id: number) => {
    const result = await api.get<Article>(`${envService.getSupportApi()}/articles/${id}`);
    return result.data;
};

const getArticleBySefUrl = async (
    productSefUrl: string,
    articleSefUrl: string,
    skipBrowserLog?: boolean,
    cookies?: string,
) => {
    const url = `${envService.getSupportApi()}/articles/by-sefurl?articleSefUrl=${articleSefUrl}&productSefUrl=${productSefUrl}`;
    const result = await api.get<Article>(url, undefined, { skipBrowserLog, cookies });
    return result.data;
};

const articlesSearch = async (queryString: string) => {
    const result = await api.get<ArticleSearchResult>(`${envService.getSupportApi()}/search/articles?${queryString}`);
    return result.data;
};

const getQuickResponseHttp = async (queryString: string) => {
    const result = await api.get<QuickResponseModel>(`${envService.getSupportApi()}/search/articles/ai?${queryString}`);
    return result.data;
};

const getArticleSection = async (sectionCode: string) => {
    const result = await api.get<Section>(`${envService.getSupportApi()}/sections/${sectionCode}`);
    return result.data;
};

const getArticleSectionBySefUrl = async (sectionUrl: string, productUrl: string, cookies?: string) => {
    const url = `${envService.getSupportApi()}/sections/by-sefurl?productSefUrl=${productUrl}&sectionSefUrl=${sectionUrl}`;
    const result = await api.get<Section>(url, undefined, { cookies });
    return result.data;
};

const createArticleRating = async (rating: Rating) => {
    const result = await api.post<Rating>(`${envService.getSupportApi()}/articles/rating`, rating);
    return result.data;
};

const updateArticleRating = async (rating: Rating) => {
    const result = await api.patch<Rating>(`${envService.getSupportApi()}/articles/rating/${rating.id}`, rating);
    return result.data;
};

const getRatingReasons = async () => {
    const result = await api.get<RatingReason[]>(`${envService.getSupportApi()}/articles/rating/reasons`);
    return result.data;
};

const getIsProductHidden = async (productCode: string) => {
    const result = await api.get<ProductActiveStatus>(`${envService.getSupportApi()}/products/${productCode}/status`);
    return result.data;
};

export {
    getArticle,
    articlesSearch,
    getArticleSection,
    getArticleSectionBySefUrl,
    getArticleBySefUrl,
    createArticleRating,
    updateArticleRating,
    getRatingReasons,
    getIsProductHidden,
    getQuickResponseHttp,
};
