import { Dispatch } from 'redux';
import { ArticleActions, ArticleActionsEnum, ProposedArticle } from './article-types';
import { editorContentFormatter } from '../../helpers/editor-content-formatter';
import { Article } from '../../models/article';
import { Product } from '../../models/product';
import { Rating } from '../../models/rating';
import { Section } from '../../models/section';
import { ArticleService } from '../../services/article.service';

export const setCurrentArticle = (article: Article): ArticleActions => {
    article.content = editorContentFormatter(article.content);
    return {
        type: ArticleActionsEnum.setCurrentArticle,
        payload: article,
    };
};

export const updateCacheArticles = (payload: Record<string, Article[]>): ArticleActions => {
    for (const sectionId in payload) {
        payload[sectionId].forEach((article: Article) => {
            article.content = editorContentFormatter(article.content);
        });
    }
    return {
        type: ArticleActionsEnum.updateCacheArticles,
        payload,
    };
};

export const setCurrentProduct = (product: Product): ArticleActions => {
    return {
        type: ArticleActionsEnum.setCurrentProduct,
        payload: product,
    };
};

export const setCurrentSection = (section: Section): ArticleActions => {
    return {
        type: ArticleActionsEnum.setCurrentSection,
        payload: section,
    };
};

export const createArticleRating =
    (rating: Rating & { articleId: number; usefulness: boolean }) => async (dispatch: Dispatch) => {
        dispatch(setArticleRating(rating));
        const ratingResult = await ArticleService.createArticleRating(rating);
        return dispatch(setArticleRating(ratingResult));
    };

export const updateArticleRating = (rating: Rating) => async (dispatch: Dispatch) => {
    dispatch(setArticleRating(rating));
    const ratingResult = await ArticleService.updateArticleRating(rating);
    return dispatch(setArticleRating(ratingResult));
};

export const setArticleRating = (rating: Rating): ArticleActions => {
    return {
        type: ArticleActionsEnum.setArticleRating,
        payload: rating,
    };
};

export const fetchRatingReasons = async (dispatch: Dispatch) => {
    const reasonsResult = await ArticleService.getRatingReasons();

    dispatch({
        type: ArticleActionsEnum.setRatingReasons,
        payload: reasonsResult,
    });
};

export const resetArticleRating = {
    type: ArticleActionsEnum.setArticleRating,
    payload: new Rating(),
};

export const setIsProductHidden = (isProductHidden: boolean): ArticleActions => {
    return {
        type: ArticleActionsEnum.setIsProductHidden,
        payload: isProductHidden,
    };
};

export const setProposedArticles = (proposedArticles: ProposedArticle[]): ArticleActions => {
    return {
        type: ArticleActionsEnum.setProposedArticles,
        payload: proposedArticles,
    };
};

